import * as React from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from "react-router-dom";
import { SvgIcon } from '@mui/material';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Moment from 'moment';
import CircleIcon from '@mui/icons-material/Circle';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { isMobile } from "react-device-detect";
import { useQuery } from "../hooks/useQuery";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '100%' : '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    paddingLeft: 4,
    paddingRight: 4
};


export const UsuariosItem = ({ type, item, refresh }) => {
    const navigate = useNavigate();
    const {deleteFornecedor} = useQuery();
    const [openConfirm, setOpenConfirm] = React.useState(false);

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    }

    const removerFornecedor = async (id) => {
        const result = await  deleteFornecedor(id);
        if (result && result.data) {
            setOpenConfirm(false);
            refresh(1);
        }
    }

    const svgAlert = () => {

        return (
            <SvgIcon style={{ width: 61, height: 65 }}>
                <svg width="61" height="55" viewBox="0 0 61 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M60.3135 46.5986C62.2682 49.9869 59.8146 54.2222 55.9103 54.2222H5.08908C1.17735 54.2222 -1.26498 49.9803 0.685963 46.5986L26.0969 2.54008C28.0526 -0.849763 32.9509 -0.843621 34.9031 2.54008L60.3135 46.5986ZM30.5 37.4896C27.8096 37.4896 25.6285 39.6707 25.6285 42.3611C25.6285 45.0516 27.8096 47.2326 30.5 47.2326C33.1905 47.2326 35.3716 45.0516 35.3716 42.3611C35.3716 39.6707 33.1905 37.4896 30.5 37.4896ZM25.8749 19.979L26.6605 34.3818C26.6973 35.0557 27.2545 35.5833 27.9294 35.5833H33.0706C33.7455 35.5833 34.3028 35.0557 34.3395 34.3818L35.1251 19.979C35.1648 19.251 34.5852 18.6389 33.8562 18.6389H27.1438C26.4147 18.6389 25.8352 19.251 25.8749 19.979Z" fill="#CCCCCC" />
                </svg>
            </SvgIcon>
        )
    }

    return (
        <Grid style={{ cursor: 'pointer' }} container spacing={1} >
            <Grid md={8} onClick={() => { type == 'PJ' ? navigate(`/pessoa-juridica/${item.id}`) : navigate(`/pessoa-fisica/${item.id}`) }}>
                <p style={{ fontWeight: 500, color: '#005EB8', margin: 0 }}>
                    <span style={{ fontWeight: '700', fontFamily: "Univers-Medium", fontSize: 16 }}>{type == 'PJ' ? item.documento.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5") : item.documento.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}</span>
                </p>
                <p style={{ fontWeight: 500, margin: 0 }}>
                    <span style={{ fontFamily: "Campuni-Medium", fontWeight: 500, fontSize: 16, color: '#292D32' }}>{type == 'PJ' ? item.razao_social : item.nome}</span>
                </p>
                {type == 'PJ' ? (
                    <p style={{ fontWeight: 500, margin: 0 }}>
                        <span style={{ fontFamily: "Univers-Medium", fontWeight: 500, fontSize: 16, color: '#666666' }}>{item && item.colaboradores.length} Colaboradore(s)</span>
                    </p>
                ) : null}
            </Grid>
            <Grid md={2} textAlign={'right'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
                {item.documento !== '00000000000000' && type == 'PJ' ? (
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => { setOpenConfirm(true); }}
                        edge="start"
                    >
                        <DeleteIcon />
                        <span style={{ fontSize: 14, marginLeft: 5, marginRight: 5 }}>{"Remover"}</span>
                    </IconButton>
                ) : null}
            </Grid>
            <Grid md={2} textAlign={'right'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => { console.log() }}
                    edge="start"
                >
                    <span style={{ fontSize: 14, marginLeft: 5, color: item.status == 'A' ? "#6BAC54" : "#FFA500", marginRight: 5 }}>{item.status == 'A' ? "Ativo" : "Inativo"}</span>
                    <CircleIcon color={item.status == 'A' ? "success" : "warning"} fontSize="10" />
                </IconButton>
            </Grid>
            <Grid md={12} xs={12}>
                <hr />
            </Grid>
            <Modal
                open={openConfirm}
                onClose={handleCloseConfirm}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container spacing={1} marginBottom={5}>
                        <Grid md={12} display={'flex'} justifyContent={'center'} marginTop={5}>
                            {svgAlert()}
                        </Grid>
                        <Grid md={12}>
                            <p style={{ fontSize: 18, color: '#666666', fontWeight: '500', textAlign: 'center' }}>Tem certeza que deseja remover esta empresa?</p>
                        </Grid>
                        <Grid md={12} display={'flex'} justifyContent={'center'} alignItems={'center'}>

                            <IconButton
                                color="inherit"
                                style={{ height: 24, width: 100 }}
                                aria-label="open drawer"
                                onClick={() => setOpenConfirm(false)}
                                edge="start"
                            >
                                <span style={{ fontSize: 12, color: '#F4455A', fontWeight: '700' }}>Cancelar</span>
                            </IconButton>


                            <Button
                                onClick={() => {
                                    removerFornecedor(item.id);
                                }}
                                style={{ width: '100px', height: 41 }}
                                variant="contained"

                            >
                                Sim
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal >
        </Grid>
    )
}